import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { axiosClient } from './axiosClient';

// Getting
export const useGetTree = () => useQuery({
	queryKey: ['newflowTrees'],
	queryFn: async () => await axiosClient.get("newflowtree")
})

// Posting
export const usePatchTree = () => {
  console.log("PATCHING PATCHING PATCHING");
	const queryClient = useQueryClient();
	return useMutation({
		mutationFn: async ({edges, nodes}) => {
			return await axiosClient.patch(`newflowtree`, {
				edges, nodes
			})
		},
		onSuccess: () => {
			queryClient.invalidateQueries([`newflowtrees`]);
			window.location.reload();
		}
	});
}

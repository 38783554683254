import React from 'react';
import { useCallback, useState, useEffect } from 'react';
import { useGetUser } from '../../../api/userApi';
import { useGetModule } from '../../../api/modulesApi';
import { Handle, Position, useReactFlow, applyNodeChanges } from 'reactflow';
import { useFlowTreeContext } from '../FlowTree';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';

import styles from '../styles.module.css';

// this function returns the label for the node based on the current state
function getLabel({ expanded, expandable, label }) {
  if (!expandable) {
    return label;
  }

  // Styling
  const arrowStyle = {
    display: 'inline-block',
    transition: 'transform 0.3s ease-in-out',
    transform: expanded ? 'rotate(180deg)' : 'rotate(0deg)',
  };

  return (
    <>
      {label} <span style={arrowStyle}>▲</span>
    </>
  )
}

export default function CustomNode({ data, id, xPos, yPos }) {
  // Queries
  const getUserQuery = useGetUser();
  const getModuleQuery = useGetModule({ moduleId: data.moduleId });

  // Nodes & Edges
  const { setNodes, addNodes, addEdges, getNodes, onNodesChange } = useReactFlow();

  // Flow Tree Context
  const { nodes } = useFlowTreeContext();

  // Expand Node
  const expandNode = (evt) => {
    evt.preventDefault();
    evt.stopPropagation();

    const updatedNodes = nodes.map((node) => {
      if (node.id === id) {
        return {
          ...node,
          data: { ...node.data, expanded: !node.data?.expanded },
        };
      }
      return node;
    });

    setNodes(updatedNodes);
  }

  // Add New Child Node
  const addChildNode = (evt) => {
    // prevent the expand/collapse behaviour when a new node is added while the
    evt.preventDefault();
    evt.stopPropagation();

    if (!data.expanded) {
      expandNode(evt);
    }

    const newNodeId = `${id}__${new Date().getTime()}`;

    // the edge between the clicked node and the child node is created
    addNodes({
      id: newNodeId,
      position: { x: xPos, y: yPos + 100 },
      data: { 
        label: 'X',
        expanded: false,
        childIndex: 0,
        moduleId: null,
      },
    });
    addEdges({ id: `${id}->${newNodeId}`, source: id, target: newNodeId });
  };

  // based on the state of the node, we show the label accordingly
  const label = getLabel(data);

  // Show Tool Tip
  const showToolTip = getModuleQuery.isSuccess && getModuleQuery.data.canva;
  const nodeContent = (
    <div className={styles.node}>
      <div className={styles.label}>{label}</div>
      <Handle position={Position.Top} type="target" id="top" />
      <Handle position={Position.Bottom} type="source" id="bottom" />
      { getUserQuery.data.data.role === "STAFF" && (
        <div className={styles.button2} onClick={addChildNode}>
          + add child node
        </div>)}
      { data.expandable && !data.expanded && (
        <div className={styles.button} onClick={expandNode}>
          + Expand
        </div>
      )}
      { data.expandable && data.expanded && (
        <div className={styles.button} onClick={expandNode}>
          - Shrink 
        </div>
      )}
    </div>
  );

  return showToolTip ? (
    <Tooltip 
      title="Click Me!" 
      placement="top"
      arrow
    >
      {nodeContent}
    </Tooltip>
  ) : (
    nodeContent
  )
}

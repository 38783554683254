import { useState, useEffect } from 'react';
import { Route, Routes, useLocation } from "react-router";
import { Box, Button, Snackbar } from '@mui/material';
import { AnimatePresence } from 'framer-motion';
import { useMainContext } from '../../providers/MainProvider';
import GraphicsPanel from './GraphicsPanel';
import Register from './Register';
import Login from './Login';
import Reset from './Reset';
import Popup from '../../global_components/Animated/Popup';
import logo from '../../assets/sidebar_icons/mortarboard.png';
import { ReactComponent as XLogo } from '../../assets/misc_icons/x-solid.svg';

export default ({compulsory, open, setOpen}) => {

  const location = useLocation();
	const [ showError, setShowError ] = useState(false);
	const [ errorMsg, setErrorMsg ] = useState(null);
	const { authType, setAuthType } = useMainContext();

	useEffect(() => {
		const splitPath = location.pathname.split("/");
		if(splitPath.length > 2 && splitPath[2] === "reset") {
			setAuthType("RESETPWD");
		}
	}, []);

	return (
		<>
			<AnimatePresence> {
				open && (
					<Popup blur setOpen={(open) => { 
						if(compulsory) {
							setShowError(true);
							setErrorMsg("Please register an account first!");
						}
						else {
							setOpen(false) 
						}
					}}>
						<Box sx={{ width: "clamp(40vw, 1450px, 90vw)", height: "clamp(40vh, 1000px, 95vh)", display: "flex", justifyContent: "stretch", backgroundColor: "white", borderRadius: "32px" }}>
							<Box sx={{ 
								flex: 6, 
								backgroundColor: "white", 
								borderRadius: "inherit", 
								position: "relative", 
								padding: "40px 60px", 
								borderLeft: "1px #E8E8E8 solid", 
								display: "flex", 
								alignItems: "center", 
								justifyContent: "center",
								overflow: "hidden"
							}}>
								<Box sx={{ position: "absolute", left: "32px", top: "32px", width: "40px", marginRight: "20px" }}><img src={logo} style={{width: "100%", margin: "auto"}}/></Box>					
								<Box sx={{ 
									position: "absolute",
									transition: "all 200ms ease-in-out",
									transform: `translateX(${authType==="REGISTER" ? "0" : "-25%"})`,
									opacity: authType==="REGISTER" ? 1 : 0,
									zIndex: authType==="REGISTER" ? 100 : 0,
								}}>
									<Register 
										setOpen={setOpen} 
										setShowError={setShowError}
										errorMsg={errorMsg}
										setErrorMsg={setErrorMsg}
									/>
								</Box>
								<Box sx={{ 
									position: "absolute",
									transition: "all 200ms ease-in-out",
									transform: `translateX(${authType==="LOGIN" ? "0" : "25%"})`,
									opacity: authType==="LOGIN" ? 1 : 0,
									zIndex: authType==="LOGIN" ? 100 : 0,
								}}>
									<Login 
										setOpen={setOpen} 
										setShowError={setShowError} 
										setErrorMsg={setErrorMsg}
									/>
								</Box>
								<Box sx={{ 
									position: "absolute",
									transition: "all 200ms ease-in-out",
									transform: `translateX(${authType==="RESETPWD" ? "0" : "-25%"})`,
									opacity: authType==="RESETPWD" ? 1 : 0,
									zIndex: authType==="RESETPWD" ? 100 : 0,
								}}>
									<Reset 
										setShowError={setShowError} 
										setErrorMsg={setErrorMsg}
									/>
								</Box>
							</Box>
							<Box sx={{ 
								flex: 8, 
								position: "relative",
								height: "100%",
								backgroundColor: "white",
								borderRadius: "inherit", 
								padding: "24px",
								paddingLeft: 0
							}}> 
								<Box sx={{
									backgroundColor: "#F3F7FF", 
									height: "100%",
									borderRadius: "32px",
									overflow: "hidden",
									position: "relative"
								}}>
									<GraphicsPanel />
								</Box>
							</Box>
							<Box sx={{ 
								flex: 8, 
								position: "relative",
								height: "100%",
								backgroundColor: "white",
								borderRadius: "inherit", 
								padding: "24px",
								paddingLeft: 0
							}}> 
								<Box sx={{
									backgroundColor: "#F3F7FF", 
									height: "100%",
									borderRadius: "32px",
									overflow: "hidden",
									position: "relative"
								}}>
									<GraphicsPanel />
								</Box>
							</Box>
						</Box>
					</Popup>
				)}
			</AnimatePresence>
			<Snackbar
				open={showError}
				autoHideDuration={3000}
				onClose={(event, reason)=>{ if(reason!=="clickaway") { setShowError(false)}}}
				message={errorMsg}
				anchorOrigin={{ vertical: "top", horizontal: "center"}}
			/>
		</>
	)
}

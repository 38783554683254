import React, { useState } from 'react';
import { Route, Routes, useLocation } from "react-router";
import { useGetUser } from './api/userApi.js';
import { AnimatePresence } from 'framer-motion';
import { Box } from '@mui/material';
import AuthPopup from './global_components/auth/AuthPopup';
import Home from './pages/home/Home';
import CareerPath from './pages/career_path/CareerPath';
import Navbar from './front_pages/components/Navbar/Navbar';
import Sidebar from './global_components/Sidebar/Sidebar';
import FlowTreePage from './pages/new_flow_tree/FlowTreePage';
import CareerPopup from './pages/career_popup/CareerPopup';
import LearningPage from './pages/learning/LearningPage';
import QandA from './pages/qa/QandA';
import Reset from './pages/reset/Reset';
import Verify from './pages/verify/Verify';
import TestingPage from './pages/TestingPage';
import Forums from './pages/forums/Forums'
import Landing from './front_pages/landing/Landing';
import CareerTree from './front_pages/career_tree/CareerTree';
import About from './front_pages/about/About';
import Contact from './front_pages/contact/Contact';
import Admin from './pages/admin/Admin';

const BarWrapper = ({location, children}) => {

	let showSidebar = false;
	let showNavbar = true;
	const splitPath = location.pathname.split("/");
	if(splitPath.length > 1) {
		showSidebar = splitPath[1] === "app";
		showNavbar = splitPath[1] !== "app";
	}
	if(splitPath.length > 2) {
		showSidebar = splitPath[2] !== "learning";
	}

	if(showSidebar) {
		return (
				<Box sx={{
					width: "100vw",
					maxWidth: "100vw",
					height: "100vh",
					display: "flex"
				}}>
					<Sidebar width="300px"/>
					<Box sx={{ width:"calc(100% - 300px)" }}>
						{children}
					</Box>
				</Box>
		)
	}
	else if(showNavbar) {
		return (
			<Box sx={{}}>
				<Navbar/>
				{children}
			</Box>
		)
	}
	else {
		return children
	}
}

export default () => {

  const location = useLocation();
	const userQuery = useGetUser();
	const [ authType, setAuthType ] = useState("REGISTER");

	return (
		<>
			<AuthPopup 
				compulsory 
				open={location.pathname.split("/")[1]==="app" 
					&& location.pathname.split("/")[2]!=="verify" 
					&& (userQuery.isError || localStorage.getItem('jwt')==null)
				} 
				authType={authType} 
				setAuthType={setAuthType}
			/>
			<BarWrapper location={location}>
					<AnimatePresence exitBeforeEnter>
						<Routes key={location.pathname} location={location}>
							<Route path="" element={<Landing />} />
							<Route path="career_tree" element={<CareerTree />} />
							<Route path="about" element={<About />} />
							<Route path="contact" element={<Contact />} />
							<Route path="app" element={<Home />} />
							<Route path="app/careerpath" element={<CareerPath />} />
							<Route path="app/careertree" element = {<FlowTreePage />} />
							<Route path="app/testingpage" element = {<TestingPage />} />
							<Route path="app/careerpopup" element = {<CareerPopup />} />
							<Route path="app/forums" element = {<Forums />} />
							<Route path="app/learning" element={<LearningPage />} />
							<Route path="app/qa" element={<QandA />} />
							<Route path="app/admin" element={<Admin />} />
							<Route path="app/verify/:userId" element={<Verify />} />
						</Routes>
					</AnimatePresence>
			</BarWrapper>
		</>
	)
}

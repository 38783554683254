import { Box, Tooltip } from '@mui/material';
import { useNavigate, useLocation } from 'react-router-dom';
import GradientButton from '../GradientButton';
import TextButton from '../TextButton';
import { ReactComponent as LogoIcon } from '../../../assets/sidebar_icons/logo.svg';

export default () => {

	const navigate = useNavigate();
	const location = useLocation();

	return (
		<Box sx={{
			position: "absolute",
			zIndex: 10,
			width: "100%",
			height: "86px",
			backgroundColor: "white",
			boxShadow: "rgb(0 0 0 / 6%) 0px 1px 5px 0px",
			display: "flex",
			alignItems: "center",
			justifyContent: "space-between",
			padding: "0 36px",
			color: "#3B3B3B"
		}}>
			<Box sx={{
				display: "flex",
				alignItems: "center",
				fontWeight: 600,
				fontSize: "24px",
				gap: "10px"
			}}>
				<LogoIcon width="42px" height="42px" />
				Advance Careers
			</Box>
				<Box sx={{
					display: "flex",
					alignItems: "center",
					transform: "translateY(6px)",
					fontWeight: 500,
					gap: "16px",
					cursor: "pointer"
				}}>
					<TextButton
						sx={{ fontSize: "16px" }}
						onClick={()=>navigate("/")}
						selected={location.pathname==="/"}
					>
						Home
					</TextButton>
					{/* <TextButton */}
					{/* 	sx={{ fontSize: "16px" }} */}
					{/* 	onClick={()=>navigate("/career_tree")} */}
					{/* 	selected={location.pathname==="/career_tree"} */}
					{/* > */}
     {/*        Career Tree */}
					{/* </TextButton> */}
					<TextButton
						sx={{ fontSize: "16px" }}
						onClick={()=>navigate("/about")}
						selected={location.pathname==="/about"}
					>
						About
					</TextButton>
					<TextButton
						sx={{ fontSize: "16px" }}
						onClick={()=>navigate("/contact")}
						selected={location.pathname==="/contact"}
					>
						Contact Us
					</TextButton>
				</Box>
			<Box>
				<GradientButton 
					sx={{ height: "42px", padding: "0 36px" }}
					clickHandler={()=>navigate("/app")}
				>
					App
				</GradientButton>
			</Box>
		</Box>
	)
}

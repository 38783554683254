import { useCallback, useState, useEffect, createContext, useContext } from 'react';
import ReactFlow, {
  applyEdgeChanges,
  applyNodeChanges,
  ReactFlowProvider,
  MiniMap,
  Background,
  useReactFlow
} from 'reactflow';
import { Button, TextField, Box } from '@mui/material';
import '../flow_tree/flowtreestyling.css';
import 'reactflow/dist/style.css';
import FlowtreePopup from './components/FlowtreePopup';

import { useControls } from 'leva';

import CustomNode from './components/CustomNode';
// import {
//   nodes as initialNodes,
//   edges as initialEdges,
// } from './initialElements';
import useAnimatedNodes from './useAnimatedNodes';
import useExpandCollapse from './useExpandCollapse';

import 'reactflow/dist/style.css';
import styles from './styles.module.css';

import { useGetTree, usePatchTree } from '../../api/newFlowTreeApi';
import { useGetUser } from '../../api/userApi';

const proOptions = { account: 'paid-pro', hideAttribution: true };

// Context
const FlowTreeContext = createContext();
export const useFlowTreeContext = () => useContext(FlowTreeContext);

// Node Type
const nodeTypes = {
  custom: CustomNode,
};

function ReactFlowPro({
  treeWidth = 220,
  treeHeight = 100,
  animationDuration = 300,
}) {

  /* Fetching Queries */
  const getUserQuery = useGetUser();
  const getTreeQuery = useGetTree(); 
  const patchTreeMutation = usePatchTree();

  /* Nodes & Edges */
  const { addNodes, addEdges, getEdges, getNodes } = useReactFlow();
	const initialNodes = getTreeQuery.isSuccess ? (getTreeQuery.data.data[0].nodes) : [];
	const initialEdges = getTreeQuery.isSuccess ? (getTreeQuery.data.data[0].edges) : [];
  const [nodes, setNodes] = useState(initialNodes);
  const [edges, setEdges] = useState(initialEdges);
  // Initialising
  useEffect(() => {
    if (getTreeQuery.isSuccess) {
      setNodes(initialNodes);
      setEdges(initialEdges);
    }
  }, [getTreeQuery.isSuccess, initialNodes, initialEdges]);

  /* States */
    // Active Node
  const [activeNode, setActiveNode] = useState({id: null});
    // Node Data
  const [nodeLabel, setNodeLabel] = useState('');
  const [nodeChildIndex, setNodeChildIndex] = useState('');
  const [nodeModuleId, setNodeModuleId] = useState('');
    // Popup Functionality
  const [popupVisible, setPopupVisible] = useState(false);
  const togglePopup = () => {
    setPopupVisible(!popupVisible);
  }

  /* Add Edge / Child Function (onConnect) */
  const onConnect = useCallback(
    (params) => {
      console.log("CONNECT CONNECT CONNECT");

      if (!params.source || !params.target) return;

      console.log('Source: ', params);

      // Check for Bottom -> Top handle
      if (params.sourceHandle === "bottom" && params.targetHandle === "top") {
        // Check for existing edges
        const existingEdges = getEdges();
        console.log('Existing Edges: ', existingEdges);
        const isNodeOccupied = existingEdges.some(
          edge => edge.target === params.target || edge.source === params.target && edge.target === params.source
        );
        if (!isNodeOccupied) {
          addEdges({
            id: `${params.source}->${params.target}`,
            source: params.source,
            target: params.target,
          });
        } else {
          console.log("Error: This node already has parent connection!")
          alert("Error: This node already has parent connection!");
        }
      } else {
        console.log("Error: Must connect from Bottom to Top handle!")
        alert("Error: Must connect from Bottom to Top handle!");
      }
    },
    [addEdges]
  );

  /* Delete Node Function */
  const deleteNode = () => {
    const newNodes = nodes.filter((node) => {
      return node.id !== activeNode.id && (!node.parentNode || node.parentNode != activeNode.id)
    });

    const newNodeIds = newNodes.map((node) => node.id);

    const newEdges = edges.filter((edge) => {
      return edge.source !== activeNode.id && edge.target !== activeNode.id && newNodeIds.includes(edge.source) && newNodeIds.includes(edge.target);
    });

    setNodes(newNodes);
    setEdges(newEdges);
  } 

  /* Collapse & Animations */
  const { nodes: visibleNodes, edges: visibleEdges } = useExpandCollapse(
    nodes,
    edges,
    { treeWidth, treeHeight }
  );
  const { nodes: animatedNodes } = useAnimatedNodes(visibleNodes, {
    animationDuration,
  });

  const onNodesChange = useCallback(
    (changes) => setNodes((nds) => applyNodeChanges(changes, nds)),
    []
  );
  const onEdgesChange = useCallback(
    (changes) => setEdges((eds) => applyEdgeChanges(changes, eds)),
    []
  );

  /* Setting Active Node */
  const onNodeClick = useCallback(
    (_, node) => {
      console.log("Active Node: ", node);
      setActiveNode(node);
      setNodes((nds) =>
        nds.map((n) => {
          if (n.id === node.id) {
            // Updating States
            setNodeLabel(node.data.label);
            setNodeModuleId(node.data.moduleId !== null ? node.data.moduleId : "");
            setNodeChildIndex(node.data.childIndex ? node.data.childIndex : 0);

            return {
              ...n,
              data: { ...n.data }, // expanded: !n.data.expanded
              style: {
                ...n.style,
                backgroundColor: 'red',
              },
            };
          } else {
            return {
              ...n,
              style: {
                ...n.style,
                backgroundColor: 'white',
              },
            };
          }
        })
      );
      togglePopup();
    },
    [setNodes, togglePopup]
  );

  // Updating Active Node
		useEffect(() => {
			setNodes((nds) =>
				nds.map((node) => {
					if (node.id === activeNode.id) {
						node.data = {
							...node.data, 
							label: nodeLabel, 
              moduleId: nodeModuleId,
              childIndex: nodeChildIndex,
						};
					}
				return node;
				})
			);
		}, [nodeLabel, nodeModuleId, nodeChildIndex]);

  return (
    <FlowTreeContext.Provider value={{ nodes, activeNode }}>
      <ReactFlow
        fitView
        nodes={animatedNodes}
        edges={visibleEdges}
        onNodesChange={onNodesChange}
        onEdgesChange={onEdgesChange}
        onNodeClick={onNodeClick}
        onConnect={onConnect}
        proOptions={proOptions}
        nodeTypes={nodeTypes}
        nodesDraggable={false}
        nodesConnectable={true}
        className={styles.viewport}
        zoomOnDoubleClick={false}
        elementsSelectable={false}
      >
        <Background />
        { popupVisible && <FlowtreePopup open={togglePopup} setOpen={setPopupVisible}/>}
        <MiniMap />
          <div>
            { (getUserQuery.data && getUserQuery.data.data.role === "STAFF") && (
              <div style={{
                position: "absolute",
                right: '10px',
                top: '10px',
                zIndex: 4,
                display: 'flex',
                flexDirection:'column',
                gap: '10px'
              }}>
                <TextField style ={{border: 'none'}} label="Label" value={nodeLabel} onChange={(evt) => setNodeLabel(evt.target.value)} />
                <TextField style ={{border: 'none'}} label="Module ID" value={nodeModuleId} onChange={(evt) => setNodeModuleId(evt.target.value)} />
                <TextField style ={{border: 'none'}} label="Child Position" value={nodeChildIndex} onChange={(evt) => setNodeChildIndex(evt.target.value === "" ? "" : Number(evt.target.value))} />
                <Button
                  variant="outlined"
                  style={{color: 'red', borderColor: 'red'}}
                  onClick={deleteNode}
                  sx={{height: '40px'}}
                >
                  Delete Node
                </Button>
                <Button 
                  variant="outlined"
                  sx = {{
                    color: '#8bc34a',
                    width: '200px',
                    borderColor: '#8bc34a',
                  }}
                  onClick={()=>patchTreeMutation.mutate({edges, nodes})}
                >
                  Save Changes
                </Button>
              </div>
            )}
          </div>
      </ReactFlow>
    </FlowTreeContext.Provider>
  );
}

function ReactFlowWrapper() {
  // 👇 This hook is used to display a leva (https://github.com/pmndrs/leva) control panel for this example.
  // You can safely remove it, if you don't want to use it.
  const levaProps = useControls({
    treeWidth: {
      value: 220,
      min: 0,
      max: 440,
    },
    treeHeight: {
      value: 100,
      min: 0,
      max: 200,
    },
    animationDuration: {
      value: 300,
      min: 0,
      max: 600,
    },
  });

  return (
      <ReactFlowProvider>
        <ReactFlowPro {...levaProps} />
      </ReactFlowProvider>
  );
}

export default ReactFlowWrapper;
